/**
 * @author Lefthandmedia <ralph@lefthandmedia.com>
 */



$(document).foundation();
$('.offcanvas-menu').height($(window).height());
$(function() {$('#dl-menu').dlmenu();});

var LHM = {};
LHM.screen = function() {
	alert('viewport width=' + $(window).width() + '\n' + 'HTML width=' + $(document).width() + '\n');
	return false;
};