/**
 * @author Lefthandmedia <ralph@lefthandmedia.com>
 */

var LHMform = {};

LHMform.showfile = function(el, target, hidden) {
	var filename = el.files[0].name;
	target.html(filename);
	hidden.val(filename);
};